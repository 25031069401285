import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setTitle } from "../redux/actions";
import TableGenerator from "./../components/table-generator";

const Contact = (props) => {
  let { setTitle } = props;
  useEffect(() => {
    setTitle("Bizimlə əlaqə");
  });
  return (
    <>
      <TableGenerator
        cols={["name", "email", "body"]}
        settings={{
          url: "admin/contact/us?per_page=5234",
          page: "contact",
        }}
      />
    </>
  );
};

export default connect(null, { setTitle })(Contact);
