import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTitle } from "../redux/actions";
import FormGenerator from "./../components/form-generator";
import TableGenerator from "./../components/table-generator";

const fields = ["media_uuid", "title", "description"];

const config = {
  url: "admin/customer/reviews",
  page: "reviews",
  deepData: true,
};

const Reviews = (props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  useEffect(() => {
    props.setTitle("Müştəri rəyləri");
  });
  return (
    <>
      <Button
        className="add-btn"
        onClick={() => {
          setVisibleModal(true);
        }}
      >
        Yeni
      </Button>
      <TableGenerator
        cols={["title", "description", "file"]}
        settings={{
          url: "admin/customer/reviews",
          page: "reviews",
          deepData: true,
          delete: true,
        }}
      />
      <Modal
        footer={null}
        onCancel={() => {
          setVisibleModal(false);
        }}
        visible={visibleModal}
      >
        <FormGenerator
          config={config}
          setVisibleModal={setVisibleModal}
          fields={fields}
        ></FormGenerator>
      </Modal>
    </>
  );
};

export default connect(null, { setTitle })(Reviews);
