import admin from "../../utils/const/api";
import types from "../types";

export const toggleMenu = () => {
  return {
    type: types.TOGGLE,
  };
};

export const logInUser = (email, password) => async (dispatch) => {
  if (email.trim().length === 0 || password.trim().length === 0) {
    dispatch({
      type: types.SET_USER_ERROR,
      payload: { message: "İstifadəçi adı və şifrə daxil edilməlidir" },
    });
  } else {
    dispatch({ type: types.LOADING_ON });
    await admin
      .post(`admin/login`, JSON.stringify({ email, password }))
      .then((res) => {
        localStorage.setItem("access_token", res.data.data.token);
        dispatch({
          type: types.SET_USER_LOGGED_IN,
          payload: res.data.data.user,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.SET_USER_ERROR,
          payload: { message: "İstifadəçi adı və ya şifrə yanlışdır" },
        });
      })
      .finally(() => {
        dispatch({ type: types.LOADING_OFF });
      });
  }
};

export const toggleLoading = (payload) => ({
  type: payload ? types.LOADING_ON : types.LOADING_OFF,
});

export const logOut = () => ({
  type: types.LOG_OUT,
});

export const changeLanguage = (payload) => {
  return {
    type: types.SET_LANG,
    payload,
  };
};

export const notify = (description, isHappy) => {
  return {
    type: types.SET_NOTIFICATION,
    payload: { description, isHappy },
  };
};

export const setTitle = (payload) => {
  return { type: types.SET_TITLE, payload };
};

const handleLocaleAndKey = (data) => {
  data.forEach((el, index) => {
    el["key"] = index + 1;
    if (el.locale) {
      delete el.locale["id"];
      Object.keys(el.locale).forEach((l) => {
        el[l] = el.locale[l];
      });
      delete el.locale;
    }
  });
  return data;
};

export const getData = (settings) => (dispatch) => {
  const params = settings.queryParams || {};
  admin.get(settings.url, { params }).then((res) => {
    let data = !settings.deepData ? res.data.data.data : res.data.data;
    if (settings.isLocaleArray) {
      data.forEach((d) => {
        d["locale"] = d.locales ? d.locales[0] : d.locale;
        delete d.locales;
      });
    }
    dispatch({
      type: types.SET_DATA,
      payload: { page: settings.page, data: handleLocaleAndKey(data) },
    });
  });
};

export const getEditData = (settings) => (dispatch) => {
  admin
    .get(`${settings.url}${settings.id && "/"}${settings.id}`)
    .then(({ data }) => {
      dispatch({
        type: types.SET_EDIT_DATA,
        payload: { page: settings.page, data: data.data },
      });
    });
};

export const resetFormData = () => (dispatch) => {
  dispatch({
    type: types.RESET_FORM,
  });
};
