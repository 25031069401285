import {
  BellFilled,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Button, Col, Popover, Row, Tooltip } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import hexagon from "../../assets/icons/hexagon.svg";
import logo from "../../assets/img/logo.svg";
import { logOut, setTitle } from "../../redux/actions";
import history from "../../utils/const/history";
import man from "./../../assets/img/man.jpg";

const TopMenu = (props) => {
  const logMeOut = () => {
    localStorage.removeItem("access_token");
    props.logOut();
    history.push("/");
  };
  const content = () => {
    return (
      <div className="profil-info">
        <Row className="border-bottom pt-1 pb-1 mb-10">
          <Col xs={4}>
            <Avatar size={38} src={man} />
          </Col>
          <Col className="border-right" xs={20}>
            <div className="flex w-100 h-100 flex-align-center pr-1">
              <h3>Admin</h3>
            </div>
          </Col>
        </Row>
        <div className="w-100  flex flex-between">
          <Button>
            <SettingOutlined /> <span>Tənzimləmələr</span>
          </Button>
          <Button onClick={logMeOut}>
            <span>Log out</span> <LogoutOutlined />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="position-relative">
      <div className="top-menu flex-align-center flex animated slideInDown ">
        {props.showDrawerButton ? (
          <Button
            type="primary"
            className="mr-20 "
            onClick={props.toggleDrawer}
          >
            {props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        ) : null}
        {props.collapsed ? (
          <>
            <Link to="/" className="d-inline">
              <img className="animated fadeIn" src={logo} alt="" />
            </Link>
          </>
        ) : null}
        <div className="top-menu-navigation">
          <div className="hexagon-title d-flex align-items-center">
            <img src={hexagon} alt="" />
            <span className="title">{props.titleReducer}</span>
          </div>
          <div>
            <Link to={`/reports`}>
              <Tooltip placement="bottomRight" title="Bildirişlər">
                <Badge>
                  <Avatar
                    className="circle-notification"
                    size={35}
                    icon={<BellFilled />}
                  />
                </Badge>
              </Tooltip>
            </Link>
            <Tooltip placement="bottomRight" title={"Admin"}>
              <Popover
                className="ml-20"
                placement="bottomRight"
                content={content()}
                trigger="click"
              >
                <Avatar size={35} src={man} />
              </Popover>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ titleReducer }) => {
  return {
    titleReducer,
  };
};
export default connect(mapStateToProps, { logOut, setTitle })(TopMenu);
