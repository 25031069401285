import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setTitle } from "../redux/actions";
import TableGenerator from "./../components/table-generator";

const Applicants = (props) => {
  let { setTitle } = props;
  useEffect(() => {
    setTitle("Vakansiyaya müraciətlər");
  });
  return (
    <>
      <TableGenerator
        cols={["name", "phone", "email", "cv_uuid"]}
        settings={{
          url: "admin/vacancy/applies",
          page: "applicants",
        }}
      />
    </>
  );
};

const mapStateToProps = ({ titleReducer }) => {
  return {
    titleReducer,
  };
};
export default connect(mapStateToProps, { setTitle })(Applicants);
