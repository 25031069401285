import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Table, Tooltip, Popconfirm, message } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getData, getEditData } from "../redux/actions";
import admin from "../utils/const/api";
import lib from "./../utils/lib";

const TableGenerator = ({ cols, settings, data, getData, getEditData }) => {
  const columns = cols.map((c) => {
    return {
      title: lib[c].label,
      dataIndex: c,
      key: c,
      render: (f) => handleRenderedType(f, lib[c]),
    };
  });

  const handleRenderedType = (item, key) => {
    switch (key.type) {
      case "photo":
        return <img className="table_image" alt="some" src={item?.file} />;
      case "link":
        return (
          <a target="_blank" rel="noreferrer" href={item}>
            View
          </a>
        );
      case "download":
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              downloadFile(item);
            }}
          >
            Download CV
          </span>
        );
      default:
        return item;
    }
  };

  const downloadFile = (uuid) => {
    admin
      .get("admin/file/downloads/" + uuid)
      .then((res) => {
        try {
          var binaryData = [];
          binaryData.push(res.data);
          const url = window.URL.createObjectURL(new Blob(binaryData));
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "todo-1.pdf";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } catch (e) {
          console.log(e.stack, "stack");
        }
      })
      .catch(() => {
        message.error("File does not exist.");
      });
  };

  if (settings.delete || settings.edit) {
    columns.push({
      title: "Əməliyyatlar",
      dataIndex: "key",
      key: "id",
      render: (index, element) => {
        return (
          <div className="flex flex-start">
            {settings.edit && (
              <Tooltip
                placement="topRight"
                className="ml-5"
                title={"Düzəliş et"}
              >
                <Button
                  onClick={() => handleItemEdit(element.id, element)}
                  className="border-none mr-5 d-flex align-items-center"
                  type="primary"
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            )}
            {settings.delete && (
              <Popconfirm
                placement="topRight"
                title={"Are you sure to delete this item?"}
                onConfirm={() => handleItemDeletion(element.id)}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip placement="topRight" className="ml-5" title={"Sil"}>
                  <Button
                    className="border-none d-flex align-items-center"
                    type="danger"
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}
          </div>
        );
      },
    });
  }

  const handleItemEdit = (id, row) => {
    getEditData({
      url: settings.url,
      id: settings.hideIdForEdit ? "" : id,
    });
    settings.editAction(row);
  };

  const handleItemDeletion = (id) => {
    admin.delete(`${settings.url}/${id}`).then((res) => {
      getData(settings);
    });
  };

  useEffect(() => {
    if (!data[settings.page]) {
      getData(settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.page]);

  return <Table dataSource={data[settings.page]} columns={columns} />;
};

const mapStateToProps = ({ data }) => {
  return { data };
};

export default connect(mapStateToProps, { getData, getEditData })(
  TableGenerator
);
