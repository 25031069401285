import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  message,
} from "antd";
import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getData } from "../redux/actions";
import admin from "../utils/const/api";
import lib, { imageLib } from "./../utils/lib";
import rules from "./../utils/rules";
import statics from "./../utils/statics";
import FileUploader from "./file-uploader";
import LocaleGenerator from "./locale-generator";
import * as moment from "moment";
const { TextArea } = Input;
const { Option } = Select;

const FormGenerator = ({
  fields,
  config,
  setVisibleModal,
  getData,
  editData,
}) => {
  const [predefineds, setPredefineds] = useState([]);
  const conf = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [activeKey, setActiveKey] = useState(0);
  const [form] = Form.useForm();
  const [locales, setLocales] = useState(["az"]);
  const [visibleErrorModal, setVisibleErrorModal] = useState(false);
  const [options, setOptions] = useState({});
  useEffect(() => {
    let drs = fields.filter((f) => lib[f].type === "dropdown");
    if (drs.length) {
      drs.forEach((d) => {
        if (lib[d].data.includes("static")) {
          let key = lib[d].data.substring(8);
          setOptions((prevState) => {
            const newState = { ...prevState };
            newState[d] = statics[key];
            return newState;
          });
        } else {
          admin.get(lib[d].data).then((res) => {
            setOptions((prevState) => {
              const newState = { ...prevState };
              newState[d] = lib[d].deepData
                ? res.data.data.data.map((d) => {
                    return {
                      id: d.id,
                      name: d.locale.name,
                    };
                  })
                : res.data.data;
              return newState;
            });
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  let items = fields.filter((f) => !lib[f].locale);

  const getItemByType = (type, form_key) => {
    let response;
    switch (type) {
      case "dropdown":
        response = (
          <Select>
            {options[form_key] &&
              options[form_key].length &&
              options[form_key].map((k) => {
                return (
                  <Option key={k.id} value={k.id}>
                    {k.name}
                  </Option>
                );
              })}
          </Select>
        );
        break;
      case "text":
        response = <Input />;
        break;
      case "area":
        response = <TextArea />;
        break;
      case "file":
        response = (
          <FileUploader
            handleImage={saveImage}
            form_key={form_key}
            predefineds={predefineds}
            settings={{ max: 1 }}
          />
        );
        break;
      case "number":
        response = <InputNumber />;
        break;
      case "date":
        response = <DatePicker format={"YYYY-MM-DD"} />;
        break;
      case "rich":
        response = (
          <JoditEditor
            ref={editor}
            value={content}
            config={conf}
            tabIndex={1}
            onBlur={(newContent) => setContent(newContent)}
            onChange={(newContent) => {}}
          />
        );
        break;
      default:
        response = <Input />;
        break;
    }
    return response;
  };

  useEffect(() => {
    if (config.actionType === "create") {
      form.resetFields();
      setPredefineds([]);
    } else {
      if (editData && Object.keys(editData).length) {
        const values = form.getFieldsValue();
        const keys = Object.keys(values);
        keys.forEach((key) => {
          if (key === "locales" && editData["locales"]) {
            const locs = values["locales"][0];
            const localeKeys = Object.keys(locs);
            const locales = ["az", "en", "ru"];
            const localesArray = [];
            locales.forEach((locale) => {
              const dat = editData["locales"].find(
                (da) => da.locale === locale
              );
              const localeObj = {};
              localeKeys.forEach((key) => {
                localeObj[key] = dat ? dat[key] : "";
              });
              localesArray.push(localeObj);
            });
            form.setFieldsValue({ locales: localesArray });
          }
          if (lib[key] && lib[key].type === "date") {
            form.setFieldsValue({ [key]: moment(new Date(editData[key])) });
          } else {
            if (Object.keys(imageLib).includes(key)) {
              if (config.takeFromRow) {
                const rowData = config.editValues[imageLib[key]];
                setPredefineds([
                  {
                    uid: editData[key],
                    name: rowData.file.split("/")[
                      rowData.file.split("/").length - 1
                    ],
                    status: "done",
                    url: rowData.file,
                  },
                ]);
              } else {
                const dt = editData[imageLib[key]];
                setPredefineds([
                  {
                    uid: editData[key],
                    name: dt.file.split("/")[dt.file.split("/").length - 1],
                    status: "done",
                    url: dt.file,
                  },
                ]);
              }
            }
            form.setFieldsValue({ [key]: editData[key] });
          }
        });
      }
    }
  }, [editData, form, config]);

  const saveData = (values) => {
    issueLocales(values);
  };

  const issueLocales = (values) => {
    values.locales.forEach((l, index) => {
      l["locale"] = locales[index];
    });
    return values;
  };

  const saveImage = (uid, form_key) => {
    let obj = {};
    obj[form_key] = uid;
    form.setFieldsValue({ ...obj });
  };

  const handleDates = (values) => {
    for (let key in values) {
      if (lib[key] && lib[key].type === "date") {
        values[key] = values[key].format("YYYY-MM-DD");
      }
    }
    return values;
  };
  const onSubmit = () => {
    let vals = form.getFieldsValue();
    const locs = ["az", "en", "ru"];
    if (vals.locales) {
      locs.forEach((l, index) => {
        vals.locales[index]["locale"] = l;
      });
    }
    if (config.actionType === "create") {
      admin.post(config.url, handleDates(vals)).then((res) => {
        getData(config);
        message.success("Item was successfully saved");
        setVisibleModal(false);
      });
    } else {
      admin
        .put(config.url + "/" + editData.id, handleDates(vals))
        .then((res) => {
          getData(config);
          message.success("Item was successfully updated");
          setVisibleModal(false);
        })
        .catch((err) => {
          message.error("Please check all the fields");
        });
    }
  };
  const hideModal = () => {
    setVisibleErrorModal(false);
  };
  const getLocaleQueue = (locs, type, key) => {
    let vals = form.getFieldsValue();
    if (type === "remove") {
      locs.splice(key, 1);
      vals.locales.splice(key, 1);
    } else {
      locs.push(key);
    }
    form.setFieldsValue(vals);
    setLocales(locs);
  };

  return (
    <Form layout="vertical" form={form} onFinish={saveData}>
      <Row gutter={[16, 16]}>
        {items.map((i, index) => {
          return (
            <Col key={index} span={lib[i].col || 6}>
              <Form.Item
                rules={
                  lib[i].validations
                    ? lib[i].validations.map((val) => {
                        return rules[val];
                      })
                    : []
                }
                name={i}
                label={lib[i].label}
              >
                {getItemByType(lib[i].type, i)}
              </Form.Item>
            </Col>
          );
        })}
        <Col span={24}>
          <LocaleGenerator
            getQueue={getLocaleQueue}
            activeKey={activeKey}
            settings={config}
            handleActiveKeyChange={(key) => setActiveKey(key)}
            fields={fields.filter((f) => lib[f].locale)}
          />
        </Col>
        <Col span={24}>
          <Button
            className="fr"
            htmlType="submit"
            type="primary"
            onClick={onSubmit}
          >
            Save
          </Button>
        </Col>
      </Row>
      <Modal footer={null} onCancel={hideModal} visible={visibleErrorModal}>
        Please enter data for all locales!!!
      </Modal>
    </Form>
  );
};

const mapStateToProps = ({ editData }) => {
  return { editData };
};

export default connect(mapStateToProps, { getData })(FormGenerator);
