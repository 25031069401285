import { Button, Col, Collapse, Form, Input, Row } from "antd";
import JoditEditor from "jodit-react";
import React, { useRef, useState } from "react";
import icons from "./../assets/icons/lang/icons";
import lib from "./../utils/lib";
import rules from "./../utils/rules";
const { TextArea } = Input;
const { Panel } = Collapse;

const langs = {
  az: "Azərbaycan dili",
  en: "İngilis dili",
  ru: "Rus dili",
};

const LocaleGenerator = ({
  fields,
  settings,
  getQueue,
  activeKey,
  handleActiveKeyChange,
}) => {
  const config = {
    readonly: false,
  };
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [locales, setLocales] = useState(["az", "en", "ru"]);
  const [reserves, setReserves] = useState([]);

  const addTranslation = (e, loc) => {
    sendBackTheQueue(locales, "add", reserves[loc]);
    let new_locales = [...locales];
    new_locales.push(reserves[loc]);
    let new_reserves = [...reserves];
    new_reserves.splice(loc, 1);
    setLocales(new_locales);
    setReserves(new_reserves);
  };

  const removeTranslation = (loc) => {
    sendBackTheQueue(locales, "remove", loc);
    let new_reserves = [...reserves];
    new_reserves.push(locales[loc]);
    let new_locales = [...locales];
    new_locales.splice(loc, 1);
    setLocales(new_locales);
    setReserves(new_reserves);
    handleActiveKeyChange(loc - 1);
  };

  const sendBackTheQueue = (locs, type, key) => {
    getQueue([...locs], type, key);
  };

  const getItemByType = (type, key) => {
    const change = settings.changeType
      ? settings.changeType.find((s) => s[0] === key)
      : false;
    if (change) {
      type = change[1];
    }
    let response;
    switch (type) {
      case "text":
        response = <Input />;
        break;
      case "area":
        response = <TextArea />;
        break;
      case "rich":
        response = (
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            tabIndex={1}
            onBlur={(newContent) => setContent(newContent)}
            onChange={(newContent) => {}}
          />
        );
        break;
      default:
        response = <Input />;
        break;
    }
    return response;
  };

  const onEdit = (targetKey, action) => {
    if (action === "remove") {
      removeTranslation(targetKey);
    }
  };
  const onChange = (activeKey) => {
    handleActiveKeyChange(activeKey ? activeKey : 0);
  };

  return (
    <>
      <Collapse
        accordion
        type="editable-card"
        hideAdd
        onChange={onChange}
        destroyInactivePanel={false}
        activeKey={activeKey.toString()}
        onEdit={onEdit}
      >
        {locales.map((l, locale_index) => (
          <Panel
            header={langs[l]}
            forceRender={true}
            showArrow={false}
            tab={langs[l]}
            key={locale_index}
            closable={locales.length > 1}
          >
            <Col span={24} key={locale_index}>
              <Row gutter={[16]}>
                {fields.map((f, field_index) => {
                  return (
                    <Col
                      className="position_relative"
                      key={`${locale_index}${field_index}`}
                      span={lib[f].col || 8}
                    >
                      <Form.Item
                        name={["locales", locale_index, f]}
                        label={lib[f].label}
                        key={`${locale_index}${field_index}`}
                        rules={
                          lib[f].validations
                            ? lib[f].validations.map((val) => {
                                return rules[val];
                              })
                            : []
                        }
                      >
                        {getItemByType(lib[f].type, f)}
                      </Form.Item>
                      <img
                        className="lang_icon"
                        src={icons[l]}
                        alt="lang icon"
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Panel>
        ))}
      </Collapse>

      {reserves.map((r, reserve_index) => {
        return (
          <Button
            onClick={(e) => addTranslation(e, reserve_index)}
            className="mr-5"
            type="primary"
            key={reserve_index}
          >
            Add translation{" "}
            <img alt="button icon" className="button_icon" src={icons[r]} />
          </Button>
        );
      })}
    </>
  );
};

export default LocaleGenerator;
