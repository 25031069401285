import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTitle } from "../redux/actions";
import FormGenerator from "./../components/form-generator";
import TableGenerator from "./../components/table-generator";

const fields = [
  "icon_uuid",
  "currency_id",
  "catalog_id",
  "salary",
  "gender",
  "expire_date",
  "title",
  "experience",
  "qualification",
  "level",
  "short_description",
  "requirements",
];

const config = {
  url: "admin/vacancies",
  page: "vacancies",
};

const Vacancies = (props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [actionType, setActionType] = useState("create");
  const [editValues, setEditValues] = useState({});
  let { setTitle } = props;
  useEffect(() => {
    setTitle("Vakansiyalar");
  });

  const openCreateEditModal = (type, row) => {
    setActionType(type);
    if (type === "edit") {
      setEditValues(row);
    }
    setVisibleModal(true);
  };
  return (
    <>
      <Button
        className="add-btn"
        onClick={() => {
          openCreateEditModal("create");
        }}
      >
        Yeni
      </Button>
      <TableGenerator
        cols={[
          "title",
          "short_description",
          "qualification",
          "level",
          "salary",
        ]}
        settings={{
          url: "admin/vacancies",
          page: "vacancies",
          edit: true,
          delete: true,
          actionType,
          editAction: (row) => {
            openCreateEditModal(`edit`, row);
          },
        }}
      />
      <Modal
        footer={null}
        onCancel={() => {
          setVisibleModal(false);
        }}
        visible={visibleModal}
      >
        <FormGenerator
          setVisibleModal={setVisibleModal}
          config={{ ...config, actionType, editValues, takeFromRow: false }}
          fields={fields}
        ></FormGenerator>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ titleReducer }) => {
  return {
    titleReducer,
  };
};
export default connect(mapStateToProps, { setTitle })(Vacancies);
