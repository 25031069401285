import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTitle } from "../redux/actions";
import FormGenerator from "./../components/form-generator";
import TableGenerator from "./../components/table-generator";

const fields = ["title", "description", "media_uuid", "catalog_id"];

const config = {
  url: "admin/portfolios",
  page: "portfolios",
  changeType: [["description", "area"]],
};
const Portfolios = (props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [actionType, setActionType] = useState("create");
  const [editValues, setEditValues] = useState({});
  let { setTitle } = props;
  useEffect(() => {
    setTitle("Protfoliolar");
  });
  const openCreateEditModal = (type, row) => {
    setActionType(type);
    if (type === "edit") {
      setEditValues(row);
    }
    setVisibleModal(true);
  };
  return (
    <>
      <Button
        className="add-btn"
        onClick={() => {
          openCreateEditModal("create");
        }}
      >
        Yeni
      </Button>
      <TableGenerator
        cols={["file", "title", "description"]}
        settings={{
          url: "admin/portfolios",
          page: "portfolios",
          edit: true,
          delete: true,
          actionType,
          editAction: (row) => {
            openCreateEditModal(`edit`, row);
          },
        }}
      />
      <Modal
        footer={null}
        onCancel={() => {
          setVisibleModal(false);
        }}
        visible={visibleModal}
      >
        <FormGenerator
          config={{ ...config, actionType, editValues, takeFromRow: true }}
          setVisibleModal={setVisibleModal}
          fields={fields}
        ></FormGenerator>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ titleReducer }) => {
  return {
    titleReducer,
  };
};
export default connect(mapStateToProps, { setTitle })(Portfolios);
