import az from "./az.svg";
import ru from "./ru.svg";
import en from "./en.svg";

let exp = {
  az,
  en,
  ru,
};
export default exp;
