import { message, Upload } from "antd";
import React, { useEffect, useState } from "react";
import admin from "./../utils/const/api";

const FileUploader = ({ settings, handleImage, form_key, predefineds }) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    setFileList(predefineds);
  }, [predefineds]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const uploadFile = ({ onSuccess, onError, file }) => {
    let form_data = new FormData();
    const filename = Math.random(1, 999999) + Date.now() + file.name;
    form_data.append("image", file, filename);
    admin
      .post("admin/file/uploads/single/image", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        handleImage(res.data.data.image_uuid, form_key);
        onSuccess(null, file);
      })
      .catch((err) => onError());
  };

  return (
    <Upload
      listType="picture-card"
      fileList={fileList}
      onChange={onChange}
      beforeUpload={beforeUpload}
      customRequest={uploadFile}
      onPreview={onPreview}
    >
      {fileList.length < settings.max && "+ Upload"}
    </Upload>
  );
};

export default FileUploader;
