const lib = {
  catalog_id: {
    label: "Kataloq",
    type: "dropdown",
    data: "admin/catalogs?per_page=631342",
    deepData: true,
    col: 4,
  },
  currency_id: {
    label: "Valyuta",
    type: "dropdown",
    data: "admin/currencies",
    deepData: false,
    col: 4,
  },
  background_uuid: {
    label: "Arxa fon şəkli",
    type: "file",
    col: 3,
  },
  media_uuid: {
    label: "Fayl",
    type: "file",
    col: 3,
  },
  profile_uuid: {
    label: "Foto",
    type: "file",
    col: 3,
  },
  cv_uuid: {
    label: "CV",
    type: "download",
    col: 3,
  },
  file: {
    label: "Foto",
    type: "photo",
    hide: true,
  },
  background: {
    label: "Arxa fon fotosu",
    type: "photo",
    hide: true,
  },
  icon_uuid: {
    label: "İkon",
    type: "file",
    col: 3,
  },
  title: {
    label: "Başlıq",
    type: "text",
    locale: true,
    col: 6,
  },
  short_description: {
    label: "Qısa izah",
    type: "text",
    locale: true,
    validations: false,
    col: 24,
  },
  description: {
    label: "Qısa izah",
    type: "rich",
    locale: true,
    validations: false,
    col: 24,
  },
  body: {
    label: "Mətn",
    type: "rich",
    locale: true,
    col: 24,
  },
  type: {
    label: "Kataloq",
    type: "dropdown",
    data: "[static]catalog_types",
  },
  name: {
    label: "Adı",
    type: "text",
    locale: true,
    col: 6,
  },
  phone: {
    label: "Əlaqə nömrəsi",
    type: "text",
  },
  email: {
    label: "Email",
    type: "text",
  },
  site: {
    label: "Veb sayt",
    type: "text",
    col: 6,
  },
  projects_count: {
    label: "Layihələrin sayı",
    type: "number",
    col: 4,
  },
  salary: {
    label: "Maaş",
    type: "number",
    col: 4,
  },
  gender: {
    label: "Cins",
    type: "dropdown",
    data: "[static]gender",
    col: 4,
  },
  expire_date: {
    label: "Son tarix",
    type: "date",
    col: 4,
  },
  experience: {
    label: "Tələb olunan təcrübə",
    type: "text",
    locale: true,
    col: 6,
  },
  qualification: {
    label: "İxtisaslaşma",
    type: "text",
    locale: true,
    col: 6,
  },
  level: {
    label: "İxtisaslaşma səviyyəsi",
    type: "text",
    locale: true,
    col: 6,
  },
  requirements: {
    label: "Tələblər",
    type: "rich",
    locale: true,
    col: 24,
  },
  youtube_url: {
    label: "Youtube linki",
    type: "link",
  },
};

export default lib;

export const imageLib = {
  background_uuid: "background",
  media_uuid: "file",
  icon_uuid: "file",
  profile_uuid: "file",
};
