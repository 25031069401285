const SET_USER = "SET_USER";
const SET_USER_ERROR = "SET_USER_ERROR";
const SET_USER_LOGGED_IN = "SET_USER_LOGGED_IN";
const GET_USER = "GET_USER";
const LOG_OUT = "LOG_OUT";

const SET_AUTHORS = "SET_AUTHORS";

const LOADING_ON = "LOADING_ON";
const LOADING_OFF = "LOADING_OFF";

const GET_OPTIONS = "GET_OPTIONS";
const SET_OPTIONS = "SET_OPTIONS";
const RE_FETCH = "RE_FETCH";

const GET_LANG = "GET_LANG";
const SET_LANG = "SET_LANG";

const TOGGLE = "TOGGLE";

const SET_TITLE = "SET_TITLE";

const SET_NOTIFICATION = "SET_NOTIFICATION";

const SET_DATA = "SET_DATA";

const SET_EDIT_DATA = "SET_EDIT_DATA";
const RESET_FORM = "RESET_FORM";

const types = {
  SET_USER,
  SET_USER_ERROR,
  SET_USER_LOGGED_IN,
  GET_USER,
  LOG_OUT,
  SET_AUTHORS,
  LOADING_ON,
  LOADING_OFF,
  GET_OPTIONS,
  SET_OPTIONS,
  RE_FETCH,
  GET_LANG,
  SET_LANG,
  TOGGLE,
  SET_TITLE,
  SET_NOTIFICATION,
  SET_DATA,
  SET_EDIT_DATA,
  RESET_FORM,
};

export default types;
