const statics = {
  catalog_types: [
    { id: 1, name: "Leadership" },
    { id: 2, name: "Works" },
    { id: 3, name: "Vacancies" },
    { id: 4, name: "News" },
  ],
  gender: [
    { id: "m", name: "Kişi" },
    { id: "f", name: "Qadın" },
    { id: "nb", name: "Fərq etmir" },
  ],
};
export default statics;
